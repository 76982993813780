<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑" : "新增" }}</div>
      <div>
        <a-form-model ref="form" :model="form.others" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="product_name" label="产品名称">
            <a-select
                    v-model="form.product_name"
                    style="width: 100%"
            >
              <a-select-option
                      v-for="val in productList"
                      :key="val.id"
                      :value="val"
              >
                {{ val.name }} ({{ val.id }})
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="number" label="批次">
            <a-input v-model="form.others.number" />
          </a-form-model-item>
          <a-form-model-item prop="produce_time" label="召回日期">
            <a-date-picker v-model="form.others.produce_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="reason" label="召回原因">
            <a-textarea v-model="form.others.reason" />
          </a-form-model-item>
          <a-form-model-item prop="situation" label="召回情况">
            <a-textarea v-model="form.others.situation" />
          </a-form-model-item>
          <a-form-model-item prop="causing_harm" label="产生危害">
            <a-textarea v-model="form.others.causing_harm" />
          </a-form-model-item>
          <a-form-model-item prop="rectification_plan" label="整改方案">
            <a-textarea v-model="form.others.rectification_plan" />
          </a-form-model-item>
          <a-form-model-item prop="ID_image" label="相关文件">
            <Upload :ID_image="form.others.ID_image" :count="5" @handleChange="handleChange" />
          </a-form-model-item>
          <a-form-model-item prop="contact" label="联系人">
            <a-input v-model="form.others.contact" />
          </a-form-model-item>
          <a-form-model-item prop="phone" label="手机号">
            <a-input v-model="form.others.phone" />
          </a-form-model-item>
          <a-form-model-item prop="email" label="邮箱">
            <a-input v-model="form.others.email" />
          </a-form-model-item>
          <a-form-model-item prop="address" label="地址">
            <a-input v-model="form.others.address" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-input v-model="form.others.remark" allowClear />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import Upload from '../../../components/Upload/index1'
import { goodsInformationList } from '@/api/goods'
import { callBackAdd, callBackEdit } from "@/api/dispatch";

export default {
  name: "FormModal",
  props: ["visible", "form", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  components: {
    Upload
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      uploadUrl: process.env.VUE_APP_NEW_URL + '/comapi/UploadApi',
      productList: [],
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      rules: {
        name: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
          { max: 64, message: "超出最大长度 (64)", trigger: "blur" },
        ],
        number: [
          { required: true, message: "请输入批次", trigger: "blur" }
        ],
        produce_time: [
          { required: true, message: "请选择召回日期", trigger: "change" }
        ],
        reason: [
          { required: true, message: "请输入召回原因", trigger: "blur" }
        ]
      },
      loading: false,
    };
  },
  created() {
    this.productList = [];
    this.productCount = 0;
    const pageSize = 999999;
    const endIf = () => this.productList.length >= this.productCount;
    const loopLoad = (page, end) => {
      return goodsInformationList({ page, page_size: pageSize }).then(
              (res) => {
                this.productCount = res.count || this.productCount;
                this.productList.push(...res.results);
                if (!end()) {
                  return loopLoad(page + 1, end);
                }
              }
      );
    };
    loopLoad(1, endIf);
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    handleChange(val) {
      this.$set(this.form.others,'ID_image',val);
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? callBackEdit : callBackAdd;
          let images = this.form.others.ID_image.filter((item) => item.status && item.status === 'done')
              .map((item) => {
                if (item.url) return item.url;
                return item.response.data.file_url;
              });
          this.form.others.ID_image = images
          func(this.form)
            .then(() => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create");
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
    },
  },
};
</script>

<style scoped></style>
